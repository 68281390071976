// ["YouTubeVideoSection", "Component"]
import React from "react";

// Default export of YouTubeVideoSection_Component
export default function YouTubeVideoSection_Component() {
  return (
    <div className="relative my-8 w-3/4 mx-auto px-2 sm:px-6 lg:px-8 rounded-[32px] bg-[#1D1D1F]">
      {/* Do not change the bg-[#1D1D1F] class */}
      {/* IMPORTANT: Overlay with opacity */}
      <div className="bg-gradient-to-br from-white to-transparent absolute top-0 left-0 right-0 bottom-0 opacity-10"></div>
      {/* IMPORTANT: Centered container with gradient background */}
      <div className="relative w-full rounded-[32px] flex items-center justify-center">
        <div className="relative w-full h-full flex flex-col sm:flex-row items-center justify-center text-white">
          {/* Left section with heading and subtext */}
          <div className="w-full sm:w-1/3 p-4">
            <h1 className="text-white font-aeonik text-25px font-normal leading-120 tracking-1.2">
              Build me a scrap inventory system!
            </h1>
            <p className="mt-5 text-white font-aeonik text-14px font-normal leading-160 opacity-50">
              See Origin build a scrap inventory management system for Crevel
              Europe. Origin was able to generate and deploy the system without
              a single developer touching the code.
            </p>
          </div>
          {/* Right section with placeholder for YouTube video */}
          <div className="w-full sm:w-2/3 p-4" id="demo">
            <div className="w-full h-full rounded-[32px] flex items-center justify-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/wgB7PRfz794?si=hld2nrBIaYg2M9YO"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
