// ["TransparentButton", "Component"]
// This component renders a transparent button with specific styling
// The button is positioned on the far right-hand side of the navigation bar

import React from "react";

const TransparentButton_Component = () => {
  return (
    <div className="flex justify-end">
      <a href="https://alpha.theorigin.ai">
        <button className="bg-transparent border border-gray-300 rounded-[69px] px-4 py-2 text-white hover:bg-gray-700">
          Log in
        </button>
      </a>
    </div>
  );
};

export default TransparentButton_Component;
