// ["ReduxStore", "Store"]    
import { configureStore } from "@reduxjs/toolkit";

// IMPORTANT: imports for all slices go here
import modalVisibilityReducer from "./ModalVisibilityState_Store"; // Importing ModalVisibilityState slice
import signUpFormReducer from "./SignUpFormState_Store"; // Importing SignUpFormState slice

const store = configureStore({
  reducer: {
    modalVisibility: modalVisibilityReducer,
    SignUpFormState: signUpFormReducer,
  },
});

export default store;
