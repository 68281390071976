// ["MainLayout", "Component"]
import React from "react";

// IMPORTANT DO NOT CHANGE
// This page uses react-router V6 which does not have Switch
// END IMPORTANT

// Import SignUpForm_Component
import SignUpForm_Component from "./SignUpForm_Component.jsx";

// Import NavigationBar_Component
import NavigationBar_Component from "./NavigationBar_Component.jsx";

// Import HeadingSection_Component
import HeadingSection_Component from "./HeadingSection_Component.jsx";

// Import LimitlessPossibilitiesSection_Component
import LimitlessPossibilitiesSection_Component from "./LimitlessPossibilitiesSection_Component.jsx";

// Import Footer_Component
import Footer_Component from "./Footer_Component.jsx";

// Import YouTubeVideoSection_Component
import YouTubeVideoSection_Component from "./YouTubeVideoSection_Component.jsx";

// Import BackgroundImage
import BackgroundImage from "./BackgroundImage_Image.jpg";

// IMPORTANT DO NOT CHANGE
// This page uses react-router V6 which does not have Switch
import { BrowserRouter } from "react-router-dom";
import CaseStudy_Component from "./CaseStudy_Component.jsx";
// END IMPORTANT

// This page is currently used to provide structure and layout to the overall site
export default function MainLayout_Component() {
  return (
    <BrowserRouter>
      <div className="relative min-h-screen overflow-y-auto bg-[#111112]">
        {/* Full-page background image */}
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, #111112 100%), url(${BackgroundImage})`,
            height: "90vh",
          }}
          aria-label="Dark fractal colors full-page background image"
        />
        {/* Render NavigationBar_Component */}
        <NavigationBar_Component />
        {/* Wrapper div without margins */}
        <div className="relative">
          {/* Render HeadingSection_Component */}
          <HeadingSection_Component />
          {/* Render YouTubeVideoSection_Component */}
          <div className="my-16">
            <YouTubeVideoSection_Component />
          </div>
          {/* Render LimitlessPossibilitiesSection_Component */}
          <div className="">
            <LimitlessPossibilitiesSection_Component />
          </div>
          <div className="">
            <CaseStudy_Component />
          </div>
          {/* Render SignUpForm_Component */}
          <div className="">
            <SignUpForm_Component />
          </div>
        </div>
        {/* Render Footer_Component with higher z-index */}
        <div className="z-50 bg-[#363638] flex items-center p-4 relative rounded-tl-[40px] rounded-tr-[40px]">
          <Footer_Component />
        </div>
      </div>
    </BrowserRouter>
  );
}
