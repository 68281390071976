import React from "react";
import Savvy from "./SavvyWarehouseImage.png";

// Import BackgroundImageNeonVectors

export default function CaseStudy_Component() {
  return (
    <div>
      {/* Background image */}
      <div className="w-full bg-transparent relative">
        {/* Centered heading with transparent background */}
        <h2 className="text-center pl-2 pr-2 text-[60px] not-italic font-normal leading-[72px] tracking-[1.2px] text-4xl font-bold tracking-tight text-white sm:text-6xl">
          Created with Origin.
        </h2>
        {/* Main content container, centered and limited to 2/3 of the width */}
        <div className="w-full max-w-4xl mx-auto lg:max-w-2/3">
          <div className="flex flex-col lg:flex-row gap-x-6 p-6 rounded-lg shadow-lg">
            {/* Image section */}
            <div className="w-full lg:w-2/3 rounded-lg overflow-hidden shadow-md mb-4 lg:mb-0">
              <img
                className="w-full h-auto object-contain"
                src={Savvy}
                alt="Savvy Image"
              />
            </div>
            {/* Text section */}
            <div className="w-full lg:w-1/3 p-4 rounded-lg shadow-md flex flex-col justify-between">
              <div className="text-white font-aeonik text-14px font-normal leading-160">
                Scrap Inventory Mgmt
              </div>
              <div className="mt-5 text-white font-aeonik text-14px font-normal leading-160 opacity-75">
                <p className="mb-4">
                  Crevel Europe GmbH is one of Europe's fastest growing food
                  wholesalers. Origin's automated, AI architect built and
                  deployed a new scrap inventory process for Crevel, including
                  integration with their ERP, without a single developer
                  touching the code.
                </p>
              </div>
              <div>
                <p className="mt-5 text-white font-aeonik text-14px font-normal leading-160">
                  <strong>
                    Use the following credentials to access the dummy site:
                  </strong>
                  <ul className="list-disc ml-4 mt-2">
                    <li>Username: Demo</li>
                    <li>Password: Demo2024!</li>
                  </ul>
                </p>
              </div>
              <div className="mt-4">
                <a
                  href="https://dummy-savvy-warehouse-319dc556.theorigin.ai/"
                  className="w-30 mt-5 flex justify-center rounded-md border border-transparent bg-[#363638] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  View demo site
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
