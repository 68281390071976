// ["LimitlessPossibilitiesSection", "Component"]

// react imports go here
import React from "react";

// Import BackgroundImageNeonVectors
import BackgroundImageNeonVectors from "./BackgroundImageNeonVectors_Image.jpg";

// Default export of LimitlessPossibilitiesSection_Component
export default function LimitlessPossibilitiesSection_Component() {
  // IMPORTANT: This component serves as a placeholder and does not interact with the context state or functionality.
  return (
    <div className="text-4xl font-bold tracking-tight text-white sm:text-6xl text-center">
      {/* Background image */}
      <div className="w-full text-center bg-transparent relative pt-32 pb-32">
        <div
          className="absolute inset-0 w-full h-full z-0 sm:h-[90vh] xs:h-[200vh]"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.8) 100%), url(${BackgroundImageNeonVectors})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.5,
          }}
          aria-label="Bright neon vector background with abstract shapes and patterns"
        />

        {/* Centered heading with transparent background */}
        <div className="flex flex-col items-center justify-center text-white z-10 relative">
          <h2 className="text-[60px] font-normal tracking-[1.2px] font-bold text-white">
            Limitless Possibilities
          </h2>
          <p className="text-[30px] tracking-normal font-normal text-white my-4">
            (minus the infosec headaches)
          </p>
          {/* New subtext under 'Limitless Possibilities' heading */}
          <div className="w-1/2">
            <p className="text-[20px] tracking-normal font-light text-white my-4">
              You know the custom tools and software your team needs - but
              getting budget sign off to build it with an agency or internal dev
              team is a different story.
            </p>
            <p className="text-[20px] tracking-normal text-white my-4 font-light">
              Origin empowers you to build and deploy custom enterprise software
              without the need for a development team.
            </p>
            <p className="text-[20px] tracking-normal font-light text-white my-4 font-light">
              Better yet, it can do its work and deploy apps within your cloud
              security perimeter, so no nasty infosec challenges.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
