// ["SignUpFormState", "Store"]
// IMPORTANT: The store key for this slice is guarenteed to be SignUpFormState. You should use this when accessing state related to this slice e.g. state.SignUpFormState.

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  email: "",
  description: "",
  errorMessages: {
    email: "",
    description: "",
  },
  submissionStatus: "idle", // can be 'idle', 'submitting', 'success', or 'error'
};

// Async thunk for form submission
export const submitSignUpForm = createAsyncThunk(
  "SignUpFormState/submitSignUpForm",
  async ({ email, description }, { rejectWithValue }) => {
    // Validate email and description
    const errors = {};
    if (!email.includes("@")) {
      errors.email = "Invalid email address";
    }
    if (description.length < 10) {
      errors.description = "Description must be at least 10 characters";
    }
    if (Object.keys(errors).length > 0) {
      return rejectWithValue(errors);
    }

    console.log("API URL: ", import.meta.env.VITE_UserDetailsAPIUrl);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_UserDetailsAPIUrl}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, description }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue({ general: error.message });
    }
  }
);

const signUpFormSlice = createSlice({
  name: "SignUpFormState",
  initialState,
  reducers: {
    // Reducer to set email
    setEmail(state, action) {
      state.email = action.payload;
    },
    // Reducer to set description
    setDescription(state, action) {
      state.description = action.payload;
    },
    // Reducer to set error messages
    setErrorMessages(state, action) {
      state.errorMessages = action.payload;
    },
    // Reducer to set submission status
    setSubmissionStatus(state, action) {
      state.submissionStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitSignUpForm.pending, (state) => {
        state.submissionStatus = "submitting";
      })
      .addCase(submitSignUpForm.fulfilled, (state) => {
        state.submissionStatus = "success";
        state.errorMessages = { email: "", description: "" };
      })
      .addCase(submitSignUpForm.rejected, (state, action) => {
        state.submissionStatus = "error";
        state.errorMessages = action.payload || {
          general: "Submission failed",
        };
      });
  },
});

// Exporting reducers and actions
export const {
  setEmail,
  setDescription,
  setErrorMessages,
  setSubmissionStatus,
} = signUpFormSlice.actions;
export default signUpFormSlice.reducer;
