// ["ModalVisibilityState", "Store"]    
// ["NewCompanySlice", "Store"]
import { createSlice } from "@reduxjs/toolkit";

// IMPORTANT: The store key for this slice is the same as the name: modalVisibility. You should use this when accessing state related to this slice.

let initialState = {
  isVisible: false,
};

// Create the newCompanySlice using createSlice
const visibilitySlice = createSlice({
  name: "modalVisibility",
  initialState,
  reducers: {
    // Reducer to toggle modal visibility
    toggleVisibility: (state) => {
      state.isVisible = !state.isVisible;
    },
  },
  extraReducers: (builder) => {},
});

export default visibilitySlice.reducer;

// Any action creator must be exported here
export const { toggleVisibility } = visibilitySlice.actions;
