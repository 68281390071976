// ["SignUpForm", "Component"]
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setDescription,
  submitSignUpForm,
} from "./SignUpFormState_Store.jsx";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"; // Import ExclamationCircleIcon
import BackgroundImageParticles from "./BackgroundImageParticles_Image.jpg"; // Import background image

// SignUpForm component initializes and connects to SignUpFormState slice
export default function SignUpForm_Component() {
  const dispatch = useDispatch();
  const { email, description, errorMessages, submissionStatus } = useSelector(
    (state) => state.SignUpFormState
  );

  // Local state for form fields
  const [localEmail, setLocalEmail] = useState(email);
  const [localDescription, setLocalDescription] = useState(description);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      submitSignUpForm({ email: localEmail, description: localDescription })
    );
  };

  // Handle email change
  const handleEmailChange = (e) => {
    setLocalEmail(e.target.value);
    dispatch(setEmail(e.target.value));
  };

  // Handle description change
  const handleDescriptionChange = (e) => {
    setLocalDescription(e.target.value);
    dispatch(setDescription(e.target.value));
  };

  return (
    <div className="relative w-full h-full min-h-[60vh] flex items-center justify-center">
      {/* Background image */}
      <img
        src={BackgroundImageParticles}
        alt="Light cloud of particles on a black background"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="relative bg-transparent w-3/4 mx-auto py-2 shadow sm:rounded-lg">
        <div className="sm:w-1/2 xs:w-full">
          <h2 className="text-left text-3xl font-extrabold text-white">
            Start creating now
          </h2>
          <p className="mt-2 text-md text-gray-300">
            Join our beta program by giving us a little bit of detail on your
            use case. Just complete the short form on the Origin ideas forge
            (which was built by Origin, obviously) to get started.
          </p>
          <div>
            <button
              type="submit"
              className="w-30 mt-5 flex justify-center rounded-md border border-transparent bg-[#363638] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              aria-label="Submit sign-up form"
              onClick={() =>
                (window.location.href =
                  "https://origin-ideas-forge-1b54529c.theorigin.ai/")
              }
            >
              Request beta invite on Origin idea forge
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
